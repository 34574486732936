@import '../../styles/colors.scss';

.pill {
  display: inline-flex;
  position: relative;
  align-items: center;
  border-radius: 8px;

  cursor: default;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding: 2px 4px;
  height: 1.2em;

  &.default {
    background: $color-pink-900;
    border: 1px solid $color-pink-900;
    color: $color-pink-300;
  }

  &.highlight {
    background: $color-pink-500;
    border: 1px solid $color-pink-500;
    color: $color-white;
  }

  &.disabled {
    background-color: $color-grey;
    color: black;
  }

  &.minWidth {
    min-width: fit-content;
  }

  .button-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.3em;

    .delete-button {
      cursor: auto;
      background: $color-pink-300;
      box-shadow: 1px 1px 0px $color-black;
      height: 1.2em;
      width: 1.2em;
      border-radius: 50%;
      padding: 0;
      transform: rotate(45deg);

      // Reset button
      border: none;

      &:hover {
        opacity: 50%;
      }
    }
  }
}
