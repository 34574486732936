@import '../../styles/breakpoints.scss';
@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.link {
  text-decoration: none !important;
  position: relative;
  display: inline-block;

  &:focus {
    outline: none;
    &:not(:has(.room.active)) {
      box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.8);
      border-radius: 15px;
    }
    & .room.active {
      background-color: darken($color-white, 10%);
    }
  }
}

.room {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 0.5em;
  box-sizing: border-box;
  box-shadow: 4px 4px 0px $color-black;
  border: solid 1px $color-black;
  border-radius: 15px;

  flex-direction: column;
  align-items: center;
  padding: 15px;

  transition: margin 0.2s linear;
  transition: box-shadow 0.2 linear;
  margin: 0;

  &.active {
    margin: -0.7em 0.7em 0 -0.7em;
    box-shadow: 8px 8px 0px $color-black;
  }

  .roomHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: normal;
  }

  h4 {
    text-align: center;
  }

  button {
    width: 145px;
    margin-top: 0.5em;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    button {
      width: 130px;
      font-size: $button-font-size-mobile;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .room {
    grid-template-columns: 2fr 1fr;
    grid-row: 2;
    grid-row-gap: 0em;
    grid-column-gap: 2em;

    :nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    :nth-child(2) {
      grid-column: 1 / 2;
      grid-row: 2;
    }

    :nth-child(3) {
      grid-row: 1 / -1;
      align-self: center;
      justify-self: center;

      margin-top: 0em;
    }

    h4 {
      text-align: start;
    }
  }
}
