@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';

.root {
  @include border-and-box-shadow();
  background-color: rgba($cta-color, 0.2);
  display: flex;

  box-shadow: border-box;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: $breakpoint-tablet) {
    margin: 0.5em 0;
  }
}

.innerContainer {
  padding: 1em;
}

.title {
  color: $color-black;
}

.text {
  color: $color-black;
}

.eventIndicator {
  @include striped-colors-background($color-pink-500, $color-white);
  display: inline-flex;
  justify-content: center;
  margin: 0.5em 0em 0.5em 1em;
  width: 0.5em;
}

.btnWrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
