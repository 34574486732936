@mixin custom-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-pink-500;
    border-radius: 20px;
  }
}

@mixin border-and-box-shadow($size: 2px) {
  box-sizing: border-box;
  box-shadow: $size $size 0px $color-black;
  border: 1px solid $color-black;
}

@mixin striped-background($color: $color-grey) {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    $color 10px,
    $color 20px
  );
}

@mixin striped-colors-background($color: $color-grey, $color2: $color-black) {
  background: repeating-linear-gradient(
    45deg,
    rgba($color2, 0.5),
    rgba($color2, 0.5) 10px,
    $color 10px,
    $color 20px
  );
}

@mixin striped-bar($color: $color-grey) {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    $color 5px,
    $color 10px
  );
}
