@import './colors.scss';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $color-black;
    animation: spinner 0.4s linear infinite;
  }

  &.medium {
    height: 24px;
    width: 24px;
  }
}
