@import '../../styles/colors.scss';
@import '../../styles/spinner.scss';
@import '../../styles/breakpoints.scss';

.roomHeader {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 1em;
}

.roomSizeContainer {
  display: flex;
  align-items: center;
}

.roomInformationContainer {
  display: flex;
  justify-content: center;
  margin: 1em 0.5em 0;
  text-align: center;
}

.roomNameContainer {
  background: $color-pink-500;
  padding: 10px;
  border: 1px solid $color-black;
  border-radius: 10px;
  position: relative;
  grid-column: 2 / 3;

  // Identical text manipulation as in the RadioBadge (black outline, white font color)
  .roomNameHeading {
    -webkit-text-stroke: 3px $color-black;

    &::after {
      content: attr(data-text);
      color: $color-white;
      position: absolute;
      top: 9px;
      left: 10px;
      -webkit-text-stroke: 0px $color-black;
    }
  }
}

.roomDetails {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;

  .clock {
    width: 100%;
    max-width: 400px;
  }

  .scheduleButton {
    margin-bottom: 2em;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .meetingSchedule-main {
    position: absolute;
  }
}

.desktop {
  &.roomWrapper {
    display: flex;
    gap: 2em;
    padding-top: 2em;
  }

  .scheduleWrapper {
    display: flex;
    position: relative;
    height: 250px;
    width: 100%;
    justify-content: center;
  }
}

.bottomSheetWrapper {
  display: flex;
  place-content: center;
  width: 100%;

  > div {
    width: 500px;
  }
}

.eventListContainer {
  flex: 1;
  display: flex;
  justify-content: center;

  > div {
    width: 50%;
  }
}
