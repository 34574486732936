.root {
  display: flex;
  align-items: center;
}

.people {
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.3;
}
