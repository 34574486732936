@import '../../styles/colors.scss';

@mixin border-and-box-shadow {
  box-sizing: border-box;
  box-shadow: 2px 2px 0px $color-black;
  border: 1px solid $color-black;
}

.container {
  @include border-and-box-shadow();
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  align-content: flex-start;
  flex: 1 1 auto;
  cursor: text;
  margin: auto;
  border-radius: 20px;
  padding: 10px 16px;
  background: transparent;
  gap: 2px;

  &.focused {
    border-radius: 20px 20px 0 0;
    border-bottom: none;
  }

  .pill-input {
    flex-grow: 1;
    text-overflow: ellipsis;
    border: none;
    outline: none;
  }
}
