@import '../../styles/colors.scss';

.container {
  box-sizing: border-box;
  box-shadow: 4px 4px 0px $color-black;
  border: solid 1px $color-black;
  border-radius: 15px;

  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.roomInformationContainer {
  display: flex;
  justify-content: center;
  text-align: center;
}

.timeslot {
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 700;
  margin-top: 0.4em;
}

.heading {
  font-size: 1.5rem;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
}

.buttonsContainerColumn {
  flex-direction: column;
}

.inputContainer {
  display: flex;
  gap: 10px;
}

.attendees {
  width: 110px;
}

.duration {
  width: 150px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
