@import '../../styles/spinner.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20.5px;
  max-width: 350px;
}

.subjectAndDuration {
  display: flex;
  gap: 10px;
  width: 100%;

  .inputRangeContainer {
    display: flex;
    flex: 0 0 160px;
  }
}
