@import '../../styles/colors.scss';

.pin {
  width: 40%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25))
    drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25));

  &:focus {
    outline: none;
    filter: drop-shadow(0px -1px 5px rgba(0, 0, 0, 0.6))
      drop-shadow(0px -1px 5px rgba(0, 0, 0, 0.6));
  }

  .outerCircle {
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-red;

    &.active {
      box-shadow: 0px 0px 12px;

      &.available {
        color: $color-green;
      }
    }

    .innerCircle {
      width: 30%;
      height: 30%;
      border-radius: 50%;
      background: $color-red;

      &.available {
        background: $color-green;
      }
    }
  }

  .stick {
    background: $color-white;
    width: 10%;
    height: 80%;
  }
}

.root {
  display: grid;
  background-image: url('../../assets/map.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  // The aspect ratio of the map image
  aspect-ratio: 1539 / 850;

  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(6, 1fr);

  filter: drop-shadow(13px 14px 18px rgba(0, 0, 0, 0.1));
}

.tomcrews {
  grid-row: 1 / 3;
  grid-column: 2 / 3;

  .pin {
    position: relative;
    left: 20%;
    top: 17%;
  }
}

.penelopecrews {
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  .pin {
    position: relative;
    top: 39%;
    left: 40%;
  }
}

.crewbb {
  grid-row: 3 / 5;
  grid-column: 1 / 2;

  .pin {
    position: relative;
    top: 38%;
    left: 30%;
  }
}

.bosse {
  grid-row: 3 / 5;
  grid-column: 2 / 3;

  .pin {
    position: relative;
    top: 38%;
    left: 45%;
  }
}

.joysrus {
  grid-row: 3 / 5;
  grid-column: 4 / 5;

  .pin {
    position: relative;
    top: 38%;
    left: 50%;
  }
}

.badcrewkor {
  grid-row: 3 / 5;
  grid-column: 6 / 7;

  .pin {
    position: relative;
    top: 38%;
    right: 10%;
  }
}

.thempyre {
  grid-row: 1 / 3;
  grid-column: 6 / 7;

  .pin {
    position: relative;
    top: 54%;
    right: 54%;
  }
}
