.pulse {
    
    animation: pulse 1s ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  
  90% {
    transform: scale(1.2);
  }
  
  100% {
    transform: scale(1);
  }
}