@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';

.meetingSchedule-main {
  width: 100%;
  margin-top: 2em;
  position: relative;
  padding: 0px 4px;
  overflow: hidden;

  .clockContainer {
    width: 100%;
    text-align: center;

    .text {
      &-success {
        color: $color-green;
      }
      &-error {
        color: $color-red;
      }
    }
  }

  .dragContainer {
    display: flex;
    width: 100%;
    margin-top: 1.5em;
    box-shadow: 4px 4px 0px $color-black;
  }

  .currentEvent {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-left: 1px;
  }

  .clockContainer {
    width: 100%;
    text-align: center;
  }

  .eventsContainer {
    width: 100%;
    z-index: 10;
    height: 100%;
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .meetingSchedule-main {
    position: absolute;
  }
}
