@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.header {
  display: flex;
  background-color: $color-pink-300;
  width: 100vw;
  height: $content-header-hight-small;

  @media only screen and (min-width: $breakpoint-tablet) {
    height: $content-header-hight-large;
  }
}

.headerButton:focus {
  outline: none;
  filter: drop-shadow(0 0 0.5rem $color-white);
}

.headerContent {
  width: 100%;
  max-width: $content-maxWidth;
  padding: 0 $content-padding;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 0 1em;
    margin: 0;
  }
}

.logo {
  display: block;
  width: 4rem;

  & img {
    width: 100%;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    width: 6rem;
  }

  &:focus {
    outline: none;
    filter: drop-shadow(0 0 0.5rem $color-pink-500);
  }
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.quickBookButton {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
}

.profileButton {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.quickBookContainer {
  position: absolute;
  z-index: 100;
  top: calc(100% + 4px);
  margin-left: 2em;
  min-width: 300px;

  > div {
    background: $color-white;
  }
}

.profileDropdown {
  @include border-and-box-shadow;
  position: absolute;
  background: white;
  z-index: 100;
  top: calc(100% + 4px);
  border-radius: 1em;

  overflow: hidden;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: 200px;

    li {
      border-radius: 1em;
      a {
        padding: 0.5em;
        display: flex;
        align-items: center;
        gap: 0.5em;
        text-decoration: none;
        color: $color-black;
        font-weight: bolder;
        cursor: pointer;
      }

      :hover {
        background: #d6d6d6;
      }
    }
  }
}
