@import '../../styles/colors.scss';
@import '../../styles/spinner.scss';
@import '../../styles/pulse.scss';

.button {
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 0 20px;
  font-weight: 700;
  // label in the button must never wrap, for design consistency
  white-space: nowrap;
  // label in the button must never overflow. The parent of the button
  // is now responsible for making enough space
  min-width: max-content;
  border-radius: 40px;
  border: 1px solid $color-black;
  box-shadow: 2px 2px 0px $color-black;
  color: $color-black;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  transform: translate(-2px, -2px);

  &.solid {
    padding: 0 20px;
  }

  &.iconButton {
    padding: 0px;
    transform: translate(0);

    svg {
      height: 35px;
      width: 35px;
      transform: scale(0.6);
    }
  }

  &.noShadow {
    box-shadow: none !important;
    border: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.green {
    background-color: $color-green;
  }

  &.red {
    background-color: $color-red;
  }

  &.yellow {
    background-color: $color-yellow;
  }

  &.transparent {
    background-color: transparent;
  }

  &.primary {
    background-color: $color-pink-500;
  }

  .medium {
    height: 35px;
  }

  .large {
    height: 40px;
    padding: 0 25px;
    font-size: 1.125rem;
  }

  &.transparent {
    background-color: transparent;
  }

  &.medium {
    min-height: 35px;
  }

  &.large {
    min-height: 40px;
    padding: 0 25px;
    font-size: 1.125rem;
  }

  &.full-width {
    width: 100%;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:active {
    transform: translate(0);
    box-shadow: 0px 0px 0px $color-black;
    left: 1px;
  }
  &:focus {
    transform: translate(-1px, -1px);
    box-shadow: 1px 1px 0px $color-black;
    left: 1px;
    outline: none;
  }

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 1px 1px 0px $color-black;

    &.green {
      background-color: rgba($color-green, 0.8);
    }

    &.red {
      background-color: rgba($color-red, 0.6);
    }

    &.yellow {
      background-color: rgba($color-yellow, 0.6);
    }

    &.transparent {
      background-color: rgba($color-pink-500, 0.1);
    }

    &.primary {
      background-color: rgba($color-pink-500, 0.6);
    }

    &.iconButton {
      transform: translate(0);
    }
  }
}
