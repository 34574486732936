@import '../../styles/spinner.scss';
@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

.schedule {
  &.container {
    @include border-and-box-shadow(4px);
    border-radius: 1em;
    overflow: hidden;
    padding: 1em;

    .topWrapper {
      display: grid;
      grid-template-columns: 2fr 9fr;

      .timelineContainer {
        grid-column: 2/2;
        overflow: hidden;
      }
    }

    .contentContainer {
      display: grid;
      grid-template-columns: 2fr 9fr;
      max-height: 100%;
      flex-direction: column;
      overflow-y: auto;

      .roomsColumn {
        display: grid;
        align-items: center;

        .roomName {
          grid-column: 1/2;
          font-weight: bold;
        }
      }

      .spinnerContainer {
        display: flex;
        position: relative;
        justify-content: center;
      }

      .scheduleTimeline {
        display: grid;
        align-items: center;
        overflow: hidden;
        position: relative;
      }

      .clockLine {
        height: 100%;
        width: 2px;
        background: $color-red;
        z-index: -1;
        position: absolute;
      }
    }
  }
}
