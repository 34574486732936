@import '../../styles/colors.scss';

.label {
  position: relative;
}

.inputBadge {
  // hide the native radio button
  appearance: none;
  position: fixed;

  & + span {
    cursor: pointer;
    display: inline-block;
    align-items: center;
    width: fit-content;
    height: 100%;
    max-height: 16px;
    padding: 5px 8px;
    font-weight: 700;
    background-color: $color-pink-500;
    color: $color-white;
    box-shadow: 2px 3px 0px $color-black;
    border: 1px solid $color-black;
    border-radius: 5px;
    -webkit-text-stroke: 3px $color-black;
    transform: translate(-2px, -3px);

    // this is a workaround to place a copy of the text on top of
    // the bordered text to avoid showing scewed lines
    // src: https://codepen.io/thlinard/pen/ZEXVQyM
    &::after {
      content: attr(data-text);
      color: $color-white;
      position: absolute;
      top: 5px;
      left: 8px;
      -webkit-text-stroke: 0px $color-black;
    }
  }

  &:checked + span {
    box-shadow: unset;
    transform: translate(0);
  }
}

.large {
  & + span {
    font-size: 1.5rem;
    padding: 10px;
    max-height: 28px;
    box-shadow: 2px 2px 0px $color-black, 3px 3px 0px $color-black, 4px 4px 0px $color-black,
      5px 5px 0px $color-black;
    border: 1px solid $color-black;
    border-radius: 10px;
    transform: translate(-5px, -5px);

    &::after {
      top: 10px;
      left: 10px;
    }
  }
}
