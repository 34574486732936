@import '../../styles/breakpoints.scss';

.children-container {
  padding: 1em;
}
.sheetContainer {
  @media only screen and (min-width: $breakpoint-tablet) {
    max-width: 50%;
  }
}
.sheetStyle {
  display: flex;
  justify-content: center;
}
