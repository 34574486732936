@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.inputText {
  @include border-and-box-shadow();
  padding: 11px 16px;
  border-radius: 20px;
  width: 100%;
  outline: 0;
}

.textArea {
  @include border-and-box-shadow();
  border-radius: 20px;
  padding: 11px 0.5em 11px 16px;
  overflow: hidden;
  min-height: 2em;

  .inner {
    width: 100%;
    min-height: 2em;
    resize: none;
    outline: 0;
    border: none;

    @include custom-scrollbar;
  }
}

.inputRange {
  display: flex;
  width: 100%;
  height: fit-content;

  .inputValueContainer {
    @include border-and-box-shadow();
    width: 100%;
    white-space: nowrap;

    &.small {
      padding: 7px 10px;
    }

    &.medium {
      padding: 11px 12px;
    }

    align-items: center;
    display: flex;
    gap: 2px;
    justify-content: center;
    background: $color-white;
    user-select: none;

    span {
      font-weight: 700;
      font-size: $input-size;
    }

    .inputValueIcon {
      display: flex;
    }
  }
}

@mixin button-border-radius($border-radius) {
  &:first-of-type {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-of-type {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

@mixin button-padding($padding-vertical, $padding-right, $padding-left) {
  &:first-of-type {
    padding: $padding-vertical $padding-right $padding-vertical $padding-left;
  }

  &:last-of-type {
    padding: $padding-vertical $padding-left $padding-vertical $padding-right;
  }
}

.inputButton {
  @include border-and-box-shadow();
  background: $color-pink-500;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &.small {
    @include button-border-radius(15px);
    @include button-padding(5.5px, 5px, 10px);
  }

  &.medium {
    @include button-border-radius(20px);
    @include button-padding(10px, 9.5px, 12px);
  }

  &:hover {
    background-color: rgba($color-pink-500, 0.9);
  }
}
