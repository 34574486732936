@import './styles/breakpoints.scss';
@import './styles/colors.scss';
@import './styles/typography.scss';

html {
  position: relative;
  // default browser setting usually sets font size to 16px
  // so 1rem will be 16px by default but scales with the users browser setting
  font-size: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-size: 0.875em;

  margin: 0;
  font-family: -apple-system, 'Inter', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
  font-size: inherit;
  // Safari
  margin: 0;
}

input,
select {
  font-family: inherit;
  font-size: $input-size;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: $color-black;
  font-weight: 700;
}

p {
  margin: 0;
}

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h4 {
  font-size: $h4-size;

  @media only screen and (max-width: $breakpoint-mobile) {
    font-size: $h4-size-mobile;
  }
}
