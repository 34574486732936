@import '../../styles/colors.scss';

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 4;
  min-width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  // this is more convinient then making the whole wrapper opeque with opacity: 0.7
  background-color: rgba($color: $color-black, $alpha: 0.7);
}

.modal {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  background-color: $color-white;
  border-radius: 20px;
  padding: 20px;
  gap: 20px;
}

.button {
  width: fit-content;
  align-self: flex-end;
}
