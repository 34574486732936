@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';

.timeline {
  &.container {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 40px;
    gap: 0;

    &.border {
      outline: 1px solid $color-black;
    }

    &.dashed {
      border-bottom: 1px dashed $color-grey;
    }

    &.thin {
      height: 1.5em;
      margin-left: 1px;
      border-bottom: 1px solid black;
    }
  }
  &.wrapper {
    width: fit-content;
    display: flex;
    height: 100%;
    will-change: transform;
    position: relative;
    cursor: move;

    &.animating {
      cursor: default;
      transition: all 0.1s ease;
    }
  }

  .inner,
  .outer {
    &.hourContainer {
      position: relative;
      flex: 1;
      height: 40px;
      width: 150px; // This might need to be inlined forward in time if we want to change scale.
    }

    &.thin {
      height: 1.5em;
    }

    .inner-hourWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1px;
      height: 100%;
    }

    .outer-hourWrapper {
      display: flex;
      align-items: flex-start;
      height: 100%;
      margin-top: 4px;
      gap: 1px;
    }

    .chunkContainer {
      display: flex;
      flex-direction: column;
      flex: 1;

      .timeIndicator {
        &.line {
          width: 1px;
          margin: 0px;
          border: none;
          pointer-events: none;
          user-select: none;
          background-color: $color-black;
          pointer-events: none;
          user-select: none;

          &.thin {
            margin-top: 1em;
          }
        }

        &.timestamp {
          &.hidden {
            opacity: 0;
          }
          position: 'relative';
          height: 0px;
          margin: 0px;
          margin-left: -1.1em; // Used for positioning timestamps in center.
          font-size: 0.7rem;
          font-weight: bold;
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}

.scheduleEvent {
  display: flex;
  position: absolute;
  align-items: center;
  height: calc(100% - 9px);
  margin-top: 3px;

  background-color: rgba($color-pink-500, 0.1);
  border: 2px dashed $color-pink-500;
  transition: background 0.3s ease-in;

  /* margin of meeting container offsets the centered text. */
  p {
    margin-bottom: 1px;
  }

  &.passed {
    @include striped-colors-background($color-grey, $color-white);
    border: 2px solid rgb(0, 0, 0);
  }

  &.disabled {
    background-color: $color-pink-500;
    border: 2px solid $color-black;
  }

  &.passed,
  &.disabled {
    height: calc(100% - 8px);
    margin-top: 2px;
  }

  &.overlap {
    /* For increased contrast on ongoing meetings */
    @include striped-colors-background($color-black, $color-red);

    border: $color-black 2px solid;
  }
}

.tooltipText {
  user-select: none;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
