@import '../../styles/colors.scss';

.container {
  position: absolute;
  width: calc(100% - 2px);
  box-shadow: 2px 2px 0px $color-black;
  border: 1px solid $color-black;
  background: $color-white;
  z-index: 4;
  border-radius: 0 0 20px 20px;

  &:last-child {
    border-bottom: none;
  }

  .spinner-container {
    position: relative;
    height: 2em;
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0.5em 0em;
  text-align: left;
  border-bottom: 1px solid $color-black;

  &.selected {
    background-color: $color-grey;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cta-color;
    color: $color-white;
    box-shadow: 2px 2px 0px $color-black;
    width: 2em;
    height: 2em;
    padding-right: auto;
    margin-right: 0.5em;
    border-radius: 50%;
    font-size: 1em;
    font-weight: bold;
  }

  .text {
    margin: 0 0 0 0.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
