@import '../../styles/colors';

.tooltipWrapper {
  position: absolute;
  display: flex;
  justify-content: center;

  .tooltipContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-black;
    white-space: nowrap;
    max-height: 3em;
    height: 2em;
    padding: 4px;
    border-radius: 4px;
    background-color: rgba($color-black, 0.75);
    color: $color-white;
  }
}
