@import '../../styles/spinner.scss';
@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

@mixin card-container {
  display: flex;
  height: 100%;
  max-width: 100%;
  border: 1px solid $color-black;
  box-shadow: 2px 2px 0px $color-black;
  transition: all 0.2s ease;
  opacity: 1;

  &.passed {
    opacity: 0.5;
  }
}

@mixin description-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  overflow: hidden;
  padding: 0.5em;

  .description-grid {
    display: grid;
    align-items: center;
    grid-template-columns: 0.3fr 1fr 0.1fr;
    gap: 4px;
    white-space: nowrap;

    &.expanded {
      grid-template-columns: 0.3fr 1fr;
    }

    .no-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .expand-icon {
      display: flex;
      justify-content: flex-end;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
      grid-template-columns: 1fr 0.1fr;

      .no-overflow.expanded {
        overflow: hidden;
        white-space: pre-wrap;
      }
    }
  }
}

@mixin pill-container {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 4px;
}

@mixin body-container {
  margin-top: 0.5em;
  padding: 0.5em 0em;
  border-top: 2px solid black;
}

.event {
  &-container {
    @include card-container();

    .event-indicator {
      width: 0.5em;
      margin: 0.5em;

      &.passed {
        @include striped-bar($color-grey);
      }

      &.ongoing {
        @include striped-bar($color-pink-500);
      }
    }

    .description-container {
      @include description-container();

      &.padded {
        padding: 1em 0.5em;
      }
    }

    .pill-container {
      @include pill-container();
    }

    .body-container {
      @include body-container();
    }
  }

  &-schedule {
    @include card-container();
    padding-left: 1.5em;
    border: 1px dashed $color-pink-500;
    background: $color-pink-300;

    .description-container {
      @include description-container();

      .error-text {
        color: $color-red;
        font-weight: bolder;
      }
    }

    .pill-container {
      @include pill-container();
    }

    .body-container {
      @include body-container();
      min-width: 100%;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
}
