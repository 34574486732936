@import './styles/breakpoints.scss';

.pageWrapper {
  position: relative;
  max-width: $content-maxWidth;
  min-height: calc(100vh - $content-header-hight-small);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 $content-padding;

  @media only screen and (min-width: $breakpoint-tablet) {
    min-height: calc(100vh - $content-header-hight-large);
    overflow-x: hidden;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 0 1em;
  }
}
