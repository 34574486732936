@import '../../styles/colors.scss';

@keyframes stretch-button {
  0% {
    width: 50px;
  }
  100% {
    width: 100%;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;

  .login {
    margin: 0 auto;
    min-width: 200px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-btn {
      background-color: $color-white;
      width: 100%;
      border: 0.1em solid $cta-color;
      color: $cta-color;
      padding: 10px 0;
      text-transform: uppercase;
      font-size: 1.125rem;
      letter-spacing: 1.5px;
      border-radius: 24px;
      animation: stretch-button 0.6s ease-in-out;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      > span {
        opacity: 0;
        animation: fade-in 0.8s;
        animation-delay: 0.2s;
        animation-fill-mode: both;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
