$cta-color: #ec5c97;
$color-black: #000000;
$color-dark-blue: #0097d7;
$color-grey: #c7c7c7;
$color-green: #2ecc71;
$color-pink-900: #9d7eb8;
$color-pink-500: #e94287;
$color-pink-300: #f4a9c5;
$color-teal-500: #00b2aa;
$color-teal-900: #0c918b;
$color-red: #f83636;
$color-white: #ffffff;
$color-yellow: #ffcd1c;
$color-status-green: rgb(235, 254, 232);
$color-status-red: rgb(251, 234, 233);
$color-status-yellow: #fffdea;
$color-disable-light-grey: #f9f9f9;
$color-disable-dark-grey: #4e4e4e;

$color-text-green: #157d41;
$color-text-red: #c50707;
