@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';

.cardGrid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .largeBookContainer {
    grid-column: 1 / 3;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0.6;

    .largeBookContainer {
      position: absolute;
      z-index: 100;
      left: 100%;
      margin-left: 2em;
      min-width: 300px;

      > div {
        background: $color-white;
      }
    }
  }
}

.viewToggle {
  display: flex;
  gap: 20px;
  margin: 1em 0;
  justify-content: center;
  @media only screen and (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.locateRoomDesktop {
  // hidden on mobile by default
  display: none;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: block;
    flex-grow: 1;
    width: 80%;
  }
}

.section {
  display: flex;
  // grid-template-columns: 1fr 4fr;
  // grid-template-rows: 1fr 0.5fr;
  gap: 2em;
  margin: 1em 0;
  min-height: calc(100vh - 96px - 2em);

  .mapWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    align-items: center;
    flex-direction: column;
    align-items: center;
  }
}
