@import '../../styles/colors.scss';

.link {
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 4px 4px 0px $color-black;
  border: solid 1px $color-black;
  border-radius: 15px;

  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: normal;
}

.heading {
  font-size: 1.5rem;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
