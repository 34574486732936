.switch {
  font-size: 1.5rem;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select {
  margin-left: 8px;
}
