@import '../../styles/breakpoints.scss';
@import '../../styles/colors.scss';

.cardGrid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
